<template>
  <div class="flex align-center" :class="customClasses">
    <VueDatePicker
      v-model="dateFrom"
      class="from"
      :placeholder="placeholder"
      utc
      :dark="dark"
      :enable-time-picker="timePicker"
    />
    <span class="cursor-default bg-gray-100 p-2 text-gray-400 border-y dark:border-gray-700 dark:bg-gray-800 text-sm"
      >to</span
    >
    <VueDatePicker
      v-model="dateTo"
      class="to"
      :placeholder="placeholder"
      utc
      :dark="dark"
      :enable-time-picker="timePicker"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import VueDatePicker from '@vuepic/vue-datepicker'

  const props = defineProps({
    modelValue: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    customClasses: {
      type: String,
      default: '',
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
  })

  const emits = defineEmits(['update:modelValue'])

  const dateFrom = computed({
    get(): any {
      return props.modelValue.length ? props.modelValue[0] : ''
    },
    set(value: string) {
      emits('update:modelValue', [value, props.modelValue[1]])
    },
  })

  const dateTo = computed({
    get(): any {
      return props.modelValue.length ? props.modelValue[1] : ''
    },
    set(value: string) {
      emits('update:modelValue', [props.modelValue[0], value])
    },
  })
</script>

<style scoped lang="scss"></style>
