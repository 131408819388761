<template>
  <li :class="{ active: isSelected }" class="training-sidebar__item" @click="selectIntent">
    <span class="training-sidebar__question">{{ intent.question }}</span>
    <ChButton
      v-if="isSelected"
      class="mr-2"
      :variant="ButtonVariant.CLEAR"
      icon="dismiss"
      :size="ButtonSize.SM"
      @click="toggleDeleteDialog"
    />
  </li>
</template>

<script setup lang="ts">
  import { computed, type PropType } from 'vue'
  import { useIntentStore } from '@/stores/intents'
  import type { Intent } from '@/types/interfaces'
  import { useGlobalStore } from '@/stores/global'
  import { useChatStore } from '@/stores/chat'
  import { ButtonSize, ButtonVariant, ChButton } from '@chatlyncom/chatlyn-ui-components'

  const chatStore = useChatStore()
  const globalStore = useGlobalStore()
  const intentStore = useIntentStore()

  const props = defineProps({
    intent: {
      type: Object as PropType<Intent>,
      default: null,
    },
  })

  const currentIntent = computed<Intent | null>(() => intentStore.getters.intent())

  const isSelected = computed(() =>
    currentIntent.value ? currentIntent.value.intentId === props.intent.intentId : false,
  )

  const selectIntent = () => {
    chatStore.mutations.setQuestion('')
    chatStore.mutations.setAnswers([])
    intentStore.mutations.setIntent(props.intent)
    intentStore.mutations.setResetedIntent(null)
  }

  const toggleDeleteDialog = () => {
    globalStore.mutations.toggleDeleteIntentDialog()
  }
</script>

<style scoped lang="scss">
  .training-sidebar {
    &__item {
      @apply w-full flex items-center justify-between gap-2 cursor-pointer rounded;

      &.active {
        @apply bg-woot-50 dark:bg-woot-800 text-woot-600 dark:text-woot-100 hover:text-woot-600 dark:hover:text-woot-100 dark:hover:bg-woot-800 hover:bg-woot-50;
      }

      &:hover {
        @apply bg-gray-100 dark:bg-gray-700;
      }
    }

    &__question {
      @apply truncate flex-1 block p-3;
    }
  }
</style>
