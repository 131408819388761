<template>
  <v-main class="main d-flex justify-center align-center">
    <!--    <div class="authentication">-->
    <v-container class="pa-3">
      <v-row class="h-100vh d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column align-center">
          <v-card rounded="md" class="auth-card mx-auto" width="480" max-width="90%">
            <v-card-item class="pb-8">
              <registration-card v-if="!requested" @request="request" />
              <done-request-card v-else />
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!--    </div>-->
  </v-main>
</template>

<script lang="ts" setup>
  import RegistrationCard from '@/components/app/auth/RegistrationCard.vue'
  import { ref } from 'vue'
  import DoneRequestCard from '@/components/app/auth/DoneRequestCard.vue'

  const requested = ref(false)

  const request = () => {
    requested.value = true
  }
</script>

<style lang="scss" scoped>
  .gradient-container {
    max-width: 100%;
    padding: 1px !important;
    position: relative;
    z-index: 1;
    background-color: rgba(var(--v-theme-secondary));
    background-image: linear-gradient(
      43deg,
      rgba(var(--v-theme-lightsecondary)) 0%,
      rgba(var(--v-theme-lightprimary)) 46%,
      rgba(var(--v-theme-lightwarning)) 100%
    );
    filter: brightness(120%);
  }

  .gradient-container:before {
    z-index: -1;
    position: absolute;
    left: 2%;
    top: 15%;
    content: '';
    width: 95%;
    opacity: 0.35;
    height: 95%;
    background-image: linear-gradient(
      43deg,
      rgba(var(--v-theme-secondary)) 0%,
      rgba(var(--v-theme-primary)) 46%,
      rgba(var(--v-theme-accent)) 100%
    );
    filter: blur(45px);
  }

  .gradient {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
  }

  .auth-card {
    border-radius: 7px;
    //border: 1px solid var(--light-theme-gray-md-new-grey, #4b4572);
    //background: linear-gradient(
    //  129deg,
    //  rgba(112, 54, 124, 0.36) 0%,
    //  rgba(40, 38, 57, 0.6) 49.94%
    //);
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(17.5px);
  }
</style>
