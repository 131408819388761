import { reactive } from 'vue'

export default reactive<any>({
  nestDialog: false,
  deleteNestDialog: false,
  deleteIntentDialog: false,
  resourceDialog: false,
  resourceEditorDialog: false,
  improveAnswerDialog: false,
  improveHistoryAnswerDialog: false,
  improveTestingAnswerDialog: false,
  loader: false,
  createUserDialog: false,
  adminDetailsSidebar: false,
  inviteMemberDialog: false,
  createOrganizationDialog: false,
  editMemberDialog: false,
  editOrganizationDialog: false,
  webCrawlerDialog: false,
  refreshPaiKeyDialog: false,
})
