<template>
  <div class="training-answers">
    <h3 class="training-answers__question" :class="{ reset: resetedIntent }">
      {{ resetedIntent ? resetedIntent.question : question }}
    </h3>
    <div v-if="resetedIntent && resetedIntent.answer" class="training-answers__reset">
      <p class="training-answers__reset-title">
        {{ $t('training.content.reset') }}
      </p>
      <p class="training-answers__reset-answer">{{ resetedIntent.answer }}</p>
    </div>
    <div class="training-answers__content">
      <div class="training-answers__header">
        <img :src="imgLoader('logo-svg', 'svg')" alt="answers logo" class="training-answers__logo" />
        <p class="training-answers__text">
          {{ $t('training.content.choose') }}
        </p>
      </div>
      <div class="training-answers__answers">
        <AiRadio
          v-for="answer in answers"
          :key="answer.ownId"
          v-model="selectedAnswer"
          :value="answer"
          :id="answer.ownId"
          class="training-answers__answer"
          @click="selectOwnAnswer(answer)"
          ><ChatAnswer :answer="answer" :selected="selectedAnswer"
        /></AiRadio>
        <AiRadio
          v-model="selectedAnswer"
          :id="ownAnswer.ownId"
          :value="ownAnswer"
          class="training-answers__own"
          @click="selectOwnAnswer(ownAnswer)"
        >
          <ChatOwnAnswer v-model="ownAnswer.text" />
        </AiRadio>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import imgLoader from '@/utils/img-loader'
  import AiRadio from '@/components/ui/AiRadio.vue'
  import { useChatStore } from '@/stores/chat'
  import { computed, reactive, ref, watch } from 'vue'
  import type { Answer, ResetedIntent } from '@/types/interfaces'
  import { useIntentStore } from '@/stores/intents'
  import ChatAnswer from '@/components/app/global/chat/ChatAnswer.vue'
  import ChatOwnAnswer from '@/components/app/global/chat/ChatOwnAnswer.vue'

  const chatStore = useChatStore()
  const intentStore = useIntentStore()

  const emits = defineEmits(['set-selected'])

  const generateId = (): string => {
    return Math.random().toString(36).substring(7)
  }

  const selectedAnswer = ref<Answer | null>(null)
  const ownAnswer = reactive<Answer>({
    answerId: 0,
    text: '',
    reaction: 'no-reaction',
    ownId: generateId(),
  })

  const question = computed(() => chatStore.getters.question())
  const resetedIntent = computed<ResetedIntent | null>(() => intentStore.getters.reseted())
  const answers = computed<Answer[] | []>(() => {
    if (!chatStore.getters.answers()) {
      return []
    }

    return chatStore.getters.answers().map((answer: Answer) => {
      return {
        ...answer,
        ownId: answer.ownId || generateId(),
      }
    })
  })

  watch(
    () => selectedAnswer.value,
    (value) => {
      emits('set-selected', value)
    },
  )

  watch(
    () => ownAnswer.text,
    (value) => {
      if (value.length > 0) {
        emits('set-selected', selectedAnswer.value)
      }
    },
  )

  const selectOwnAnswer = (answer: Answer) => {
    selectedAnswer.value = answer
  }
</script>

<style scoped lang="scss">
  .training-answers {
    @apply flex flex-col overflow-y-auto;

    &__question {
      @apply bg-woot-50 dark:bg-woot-900 truncate w-full py-8 px-32 dark:text-white;

      &.reset {
        @apply bg-transparent dark:bg-transparent;
      }
    }

    &__reset {
      @apply bg-woot-50 dark:bg-woot-900 w-full py-8 px-32;

      &-title {
        @apply text-woot-500 text-sm mb-2;
      }
    }

    &__content {
      @apply flex flex-col py-4;
    }

    &__header {
      @apply flex px-16;
    }

    &__logo {
      @apply bg-white dark:bg-gray-950 w-8 h-8 rounded p-1;
    }

    &__text {
      @apply text-sm w-full ml-8 dark:text-white;
    }

    &__answers {
      @apply flex flex-col items-start px-32;
    }

    &__answer {
      @apply w-full relative;
    }

    &__own {
      @apply w-full pr-8;
    }
  }
</style>
