<template>
  <main class="main h-screen mx-0 max-w-full">
    <div class="container flex flex-col gap-y-4">
      <div class="w-full flex items-end gap-x-4">
        <ChInput
          v-model="search"
          class="flex-1"
          :placeholder="$t('admin.accounts.search')"
          icon="search"
          clearable
          @clean="clean"
        />
        <ChButton prepend-icon="add" @click="toggleCreateUserDialog">{{ $t('admin.accounts.new') }}</ChButton>
      </div>
      <ChTable :headers="tableHeader" :table-data="users.items" class="h-auto">
        <template #actions="{ row }">
          <ChButton
            v-if="row.nest && row.organization"
            :size="ButtonSize.XS"
            :color="ColorScheme.SECONDARY"
            @click="openAccount(row)"
            >{{ $t('admin.accounts.open') }}</ChButton
          >
        </template>
        <template #pagination>
          <ChTablePagination v-model="page" :page-size="10" :total-count="users.count" />
        </template>
      </ChTable>
    </div>
    <AiDialog
      v-if="showCreateUserDialog"
      :title="$t('admin.accounts.create-dialog-title')"
      @close="toggleCreateUserDialog"
    >
      <CreateUser @success="successCreateUser" />
    </AiDialog>
  </main>
</template>

<script lang="ts" setup>
  import CreateUser from '@/components/admin/CreateUser.vue'
  import {
    ButtonSize,
    ChButton,
    ChInput,
    ChTable,
    ChTablePagination,
    ColorScheme,
  } from '@chatlyncom/chatlyn-ui-components'
  import { useAdminStore } from '@/stores/admin'
  import { computed, onMounted, ref, watch } from 'vue'
  import { debounce } from 'lodash'
  import AiDialog from '@/components/ui/AiDialog.vue'
  import type { DetailsUserInterface, ResponseDetailsUsersInterface } from '@/types/interfaces'
  import { showErrors } from '@/utils/errors'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const adminStore = useAdminStore()

  const tableHeader = [
    {
      name: 'ID',
      width: '16px',
      value: 'userId',
    },
    {
      name: 'Email',
      width: 'flex-1',
      value: 'email',
    },
  ]

  const page = ref(1)
  const search = ref('')
  const showCreateUserDialog = ref(false)

  const users = computed<ResponseDetailsUsersInterface>(() => adminStore.getters.users())

  const fetchUsersDebounced = debounce(async () => {
    if (search.value === '' || search.value.length >= 3) {
      page.value = 1
      await adminStore.actions.fetchUsers(page.value, search.value)
    }
  }, 500)

  const fetchUsers = async () => {
    await adminStore.actions.fetchUsers(page.value, search.value)
  }

  const clean = () => {
    search.value = ''
  }

  const toggleCreateUserDialog = () => {
    showCreateUserDialog.value = !showCreateUserDialog.value
  }

  const successCreateUser = () => {
    fetchUsers()
    toggleCreateUserDialog()
  }

  const openAccount = (user: DetailsUserInterface) => {
    user.nest && user.organization
      ? window.open(`#/${user.organization.id}/nest/${user.nest.id}`, '_blank')
      : showErrors(t('admin.accounts.link-error'))
  }

  watch(search, fetchUsersDebounced)
  watch(page, fetchUsers)

  onMounted(() => {
    fetchUsers()
  })
</script>
