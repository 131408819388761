export default (state: any) => {
  const setUser = (payload: any) => {
    state.user = payload
  }

  const setUsers = (payload: any) => {
    state.users = payload
  }

  const signIn = () => {
    state.auth = true
  }

  const signOut = () => {
    state.auth = false
  }

  return {
    setUser,
    setUsers,
    signIn,
    signOut,
  }
}
