import { computed, type Ref } from 'vue'

export const useCron = (cronString: Ref<string>, hours: Ref<string>, minutes: Ref<string>, days: Ref<string[]>) => {
  const formatSequence = (acc: string, val: number, index: number, array: number[]) => {
    if (index === 0 || array[index - 1] !== val - 1) {
      return acc + ',' + val.toString()
    } else if (index < array.length - 1 && array[index + 1] !== val + 1) {
      return acc + '-' + val.toString()
    } else if (index === array.length - 1) {
      return acc + '-' + val.toString()
    }

    return acc
  }

  const condense = (array: number[]) => {
    return array.reduce(formatSequence, '').substring(1)
  }

  return computed(() => {
    const cronArray = cronString.value.split(' ')

    cronArray[1] = hours.value
    cronArray[0] = minutes.value

    if (days.value.length === 7 || days.value.length === 0) {
      cronArray[4] = '*'
    } else {
      const sortedDays = [...days.value.map(Number)].sort((a, b) => a - b)

      cronArray[4] = condense(sortedDays)
    }

    return cronArray.join(' ')
  })
}
