import { toast } from 'vue3-toastify'
import nests from '@/api/nests/nests'
import { useRouter } from 'vue-router'
import type { CreateNestDto } from '@/types/interfaces'

export default (mutations: any) => {
  const router = useRouter()
  const fetchNests = async (organizationId: string) => {
    try {
      mutations.setFlags('loading', true)
      const { data } = await nests.fetchNests(organizationId)
      mutations.setNests(data)
      mutations.setFlags('loading', false)
    } catch (error) {
      toast.error((error as any).response.data.message)
      mutations.setFlags('loading', false)
    }
  }
  const fetchNest = async (organizationId: string, nestId: string) => {
    try {
      const { data } = await nests.fetchNest(organizationId, nestId)
      mutations.setNest(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchNestOptions = async (organizationId: string, nestId: string) => {
    try {
      const { data } = await nests.fetchNestOptions(organizationId, nestId)
      mutations.setNestOptions(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const createNest = async (organizationId: number, nest: CreateNestDto) => {
    try {
      const { data } = await nests.createNest(organizationId, nest)
      mutations.setCreatedNest(data)
      await toast.success('Successfully created')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateNestOptions = async (organizationId: string, nestId: string, data: any) => {
    try {
      await nests.updateNestOptions(organizationId, nestId, data)
      await toast.success('Successfully saved')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const deleteNest = async (organizationId: string, nestId: string) => {
    try {
      await nests.deleteNest(organizationId, nestId)
      const { data } = await nests.fetchNests(organizationId)
      await mutations.setNests(data)
      await router.push({ name: 'home' })
      await toast.success('Successfully deleted')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const generateSecret = async (organizationId: string, nestId: string) => {
    try {
      const { data } = await nests.generateSecret(organizationId, nestId)
      mutations.setSecret(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchSecret = async (organizationId: string, nestId: string) => {
    try {
      const { data } = await nests.fetchSecret(organizationId, nestId)
      mutations.setSecret(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const revokeSecret = async (organizationId: string, nestId: string) => {
    try {
      await nests.revokeSecret(organizationId, nestId)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchNests,
    fetchNest,
    fetchNestOptions,
    createNest,
    updateNestOptions,
    deleteNest,
    generateSecret,
    fetchSecret,
    revokeSecret,
  }
}
