<template>
  <div class="crawler-content">
    <div v-if="extractingUrlsFlag" class="crawler-content__loading">
      <ChSpinner class="crawler-content__loader" :size="32" />
      <h3 class="crawler-content__title">
        {{ $t('crawler-dialog.third-step.loading.title') }}
      </h3>
      <p class="crawler-content__description">
        {{ $t('crawler-dialog.third-step.loading.description') }}
      </p>
    </div>
    <div v-else class="crawler-content__wrapper">
      <div class="crawler-content__info">
        <h3 class="crawler-content__title">
          {{
            $t('crawler-dialog.third-step.info.title', {
              count: extractedUrls.length,
            })
          }}
        </h3>
        <p class="crawler-content__description">
          {{ $t('crawler-dialog.third-step.info.description') }}
        </p>
      </div>
      <ChTable
        class="crawler-content__table"
        v-model="selected"
        inverted
        :headers="[{ name: 'URL', value: 'url' }]"
        :table-data="extractedUrls"
      />
      <ChInput
        v-model="selectors"
        placeholder="article.content"
        class="crawler-content__input crawler-content__selector"
        :label="$t('crawler-dialog.third-step.selector.label')"
        :info-text="$t('crawler-dialog.third-step.selector.tooltip')"
      />
    </div>
    <div class="crawler-content__actions">
      <ChButton
        v-if="!configPage"
        class="crawler-content__btn crawler-content__btn--back"
        prepend-icon="arrow-left"
        :color="ColorScheme.SECONDARY"
        @click="prevStep"
        >{{ $t('crawler-dialog.second-step.btn.back') }}</ChButton
      >
      <ChButton
        class="crawler-content__btn crawler-content__btn--save"
        :disabled="isDisabled"
        :color="ColorScheme.SECONDARY"
        @click="save"
        >{{ $t('crawler-dialog.third-step.btn.save') }}</ChButton
      >
      <ChButton
        v-if="!configPage"
        class="crawler-content__btn crawler-content__btn--run"
        :disabled="isDisabled"
        @click="saveAndRun"
        >{{ $t('crawler-dialog.third-step.btn.save-and-run') }}</ChButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ChButton, ChInput, ChSpinner, ChTable, ColorScheme } from '@chatlyncom/chatlyn-ui-components'
  import { computed, ref, watch } from 'vue'
  import { useCrawlerStore } from '@/stores/crawler'

  interface ThirdStepProps {
    configPage?: boolean
    url: string
  }

  const crawlerStore = useCrawlerStore()

  const { configPage = false, url } = defineProps<ThirdStepProps>()

  const emits = defineEmits(['prev-step', 'save', 'save-and-run'])

  const selectors = ref('')
  const selected = ref<number[]>([])
  const firstTime = ref(true)

  const extractedUrls = computed(() => {
    const urls = crawlerStore.getters.extractedUrls()

    return urls.length === 0 ? [{ id: 0, url }] : urls
  })

  const extractingUrlsFlag = computed(() => {
    return crawlerStore.getters.extractingUrlsFlag()
  })

  const isDisabled = computed(() => selected.value.length === 0)

  const prevStep = () => {
    emits('prev-step')
  }

  const save = () => {
    emits('save', selectors.value)
  }

  const saveAndRun = () => {
    emits('save-and-run', selectors.value)
  }

  watch(
    extractedUrls,
    (newVal) => {
      if (firstTime.value && !extractingUrlsFlag.value) {
        firstTime.value = false
        selected.value = newVal.map((url) => url.id)
        crawlerStore.mutations.setSelectedUrls(newVal.map((url) => url.id))
      }
    },
    { immediate: true },
  )

  watch(selected, (newVal) => {
    crawlerStore.mutations.setSelectedUrls(newVal)
  })
</script>

<style scoped lang="scss">
  .crawler-content {
    &__loading {
      @apply p-4 flex flex-col items-center text-center w-full bg-woot-50 rounded-lg text-woot-700 text-sm mb-4 dark:bg-woot-700 dark:text-woot-100;
    }

    &__info {
      @apply p-4 flex flex-col w-full bg-woot-50 rounded-lg text-woot-700 text-sm mb-4 dark:bg-woot-700 dark:text-woot-100;
    }

    &__title {
      @apply font-semibold;
    }

    &__table {
      max-height: 300px;

      @apply dark:text-gray-400 mb-4;
    }

    &__selector {
      @apply mb-4;
    }

    &__actions {
      @apply w-full flex items-center mb-4;
    }

    &__btn {
      &--save {
        @apply ml-auto;
      }

      &--run {
        @apply ml-4;
      }
    }
  }
</style>
