export default (state: any) => {
  const toggleNestDialog = () => {
    state.nestDialog = !state.nestDialog
  }

  const toggleDeleteNestDialog = () => {
    state.deleteNestDialog = !state.deleteNestDialog
  }

  const toggleResourceDialog = () => {
    state.resourceDialog = !state.resourceDialog
  }

  const toggleResourceEditorDialog = () => {
    state.resourceEditorDialog = !state.resourceEditorDialog
  }

  const toggleImproveAnswerDialog = () => {
    state.improveAnswerDialog = !state.improveAnswerDialog
  }

  const toggleInviteMemberDialog = () => {
    state.inviteMemberDialog = !state.inviteMemberDialog
  }

  const toggleCreateOrganizationDialog = () => {
    state.createOrganizationDialog = !state.createOrganizationDialog
  }

  const toggleCreateUserDialog = () => {
    state.createUserDialog = !state.createUserDialog
  }

  const toggleEditMemberDialog = () => {
    state.editMemberDialog = !state.editMemberDialog
  }

  const toggleEditOrganizationDialog = () => {
    state.editOrganizationDialog = !state.editOrganizationDialog
  }

  const toggleDeleteIntentDialog = () => {
    state.deleteIntentDialog = !state.deleteIntentDialog
  }

  const toggleWebCrawlerDialog = () => {
    state.webCrawlerDialog = !state.webCrawlerDialog
  }

  const toggleRefreshApiKeyDialog = () => {
    state.refreshApiKeyDialog = !state.refreshApiKeyDialog
  }

  const toggleLoader = () => {
    state.loader = !state.loader
  }

  const showLoader = () => {
    state.loader = true
  }

  const hideLoader = () => {
    state.loader = false
  }

  const toggleCreateDialog = () => {
    state.createDialog = !state.createDialog
  }

  const showCreateDialog = () => {
    state.createDialog = true
  }

  const hideCreateDialog = () => {
    state.createDialog = false
  }

  const toggleAdminDetailsSidebar = () => {
    state.adminDetailsSidebar = !state.adminDetailsSidebar
  }

  const showAdminDetailsSidebar = () => {
    state.adminDetailsSidebar = true
  }

  const hideAdminDetailsSidebar = () => {
    state.adminDetailsSidebar = false
  }

  return {
    toggleNestDialog,
    toggleDeleteNestDialog,
    toggleResourceDialog,
    toggleResourceEditorDialog,
    toggleImproveAnswerDialog,
    toggleInviteMemberDialog,
    toggleCreateOrganizationDialog,
    toggleCreateUserDialog,
    toggleEditMemberDialog,
    toggleEditOrganizationDialog,
    toggleDeleteIntentDialog,
    toggleWebCrawlerDialog,
    toggleRefreshApiKeyDialog,
    toggleLoader,
    showLoader,
    hideLoader,
    toggleCreateDialog,
    showCreateDialog,
    hideCreateDialog,
    toggleAdminDetailsSidebar,
    showAdminDetailsSidebar,
    hideAdminDetailsSidebar,
  }
}
