export enum ResourceStatusEnums {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
}

export enum ResourceTypeEnums {
  PDF = 'PDF',
  TEXT = 'TEXT',
  WEB = 'WEBPAGE',
}
