import axios from 'axios'

interface getUsersParams {
  limit: number
  page?: number
  email?: string
}

export default {
  getMe: () => {
    return axios.get('/users/me')
  },

  getUsers: (page: number, email: string) => {
    const params: getUsersParams = {
      limit: 10,
    }
    if (email) {
      params.email = email
    }
    if (page) {
      params.page = page
    }

    return axios.get('/users/details', { params })
  },

  getUser: (id: string) => {
    return axios.get('/users/' + id)
  },

  updateUser: (id: string, data: any) => {
    return axios.patch('/users/' + id, data)
  },

  changeUserPassword: (id: string, data: any) => {
    return axios.patch(`/users/${id}/password`, data)
  },

  suspendUser: (id: string) => {
    return axios.patch(`/users/${id}/suspend`)
  },

  createUser: (data: any) => {
    return axios.post(`/users`, data)
  },
}
