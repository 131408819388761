import crawlerApi from '@/api/crawler'
import { toast } from 'vue3-toastify'
import { useI18n } from 'vue-i18n'
import type { CreateCrawlerBody, ExtractUrlsBody } from '@/types/interfaces'

interface WebScrapersParamsInterface {
  organizationId: string
  nestId: string
  page: any
  limit?: number
}

export default (mutations: any) => {
  const { t } = useI18n()

  const fetchFeatureFlags = async (organizationId: string) => {
    try {
      const { data } = await crawlerApi.fetchFeaturesFlags(organizationId)
      mutations.setFeatureFlags(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const extractUrls = async (organizationId: string, nestId: string, body: ExtractUrlsBody) => {
    try {
      mutations.toggleExtractingUrlsFlag()

      const { data } = await crawlerApi.extractUrls(organizationId, nestId, body)

      mutations.setExtractedUrls(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    } finally {
      mutations.toggleExtractingUrlsFlag()
    }
  }

  const fetchWebScrapers = async ({ organizationId, nestId, page, limit = 50 }: WebScrapersParamsInterface) => {
    try {
      mutations.clearWebScrapers()
      const { data } = await crawlerApi.fetchWebScrapers(organizationId, nestId, page, limit)
      mutations.setWebScrapers(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const fetchScraper = async (organizationId: string, nestId: string, scraperId: string) => {
    try {
      const { data } = await crawlerApi.fetchScraper(organizationId, nestId, scraperId)
      mutations.setScraper(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const fetchScraperWebpages = async (
    organizationId: string,
    nestId: string,
    scraperId: string,
    page: number,
    search?: string,
    orderBy?: string,
    orderDirection?: string,
  ) => {
    try {
      const { data } = await crawlerApi.fetchScraperPages(
        organizationId,
        nestId,
        scraperId,
        page,
        search,
        orderBy,
        orderDirection,
      )
      mutations.setWebpages(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const addPages = async (organizationId: string, nestId: string, scraperId: string, urls: string[]) => {
    try {
      const { data } = await crawlerApi.addPages(organizationId, nestId, scraperId, urls)
      mutations.setUrlsTypes(data)
      toast.success(t('web-crawler-dialog.notifications.added-pages'))
      return data
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const deleteScraperPages = async (organizationId: string, nestId: string, scraperId: string, ids: number[]) => {
    try {
      await crawlerApi.deleteScraperPages(organizationId, nestId, scraperId, ids)
      toast.success(t('web-crawler-dialog.notifications.deleted-pages'))
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const createCrawler = async (organizationId: string, nestId: string, body: CreateCrawlerBody) => {
    try {
      const { data } = await crawlerApi.createCrawler(organizationId, nestId, body)
      toast.success(t('web-crawler-dialog.notifications.created'))
      mutations.setScraper(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const runScraper = async (organizationId: string, nestId: string, scraperId: string, urls: number[]) => {
    try {
      await crawlerApi.runScraper(organizationId, nestId, scraperId, urls)
      toast.success(t('web-crawler-dialog.notifications.started-scrapper'))
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const updateWebScraper = async (organizationId: string, nestId: string, scraperId: string, body: any) => {
    try {
      await crawlerApi.updateWebScraper(organizationId, nestId, scraperId, body)
      toast.success(t('web-crawler-dialog.notifications.updated'))
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  const deleteScraper = async (organizationId: string, nestId: string, crawlerId: string) => {
    try {
      await crawlerApi.deleteScraper(organizationId, nestId, crawlerId)
      toast.success(t('web-crawler-dialog.notifications.deleted'))
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchFeatureFlags,
    extractUrls,
    fetchWebScrapers,
    fetchScraper,
    fetchScraperWebpages,
    addPages,
    deleteScraperPages,
    updateWebScraper,
    createCrawler,
    runScraper,
    deleteScraper,
  }
}
