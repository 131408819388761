<template>
  <form @submit.prevent="create" class="auth-form">
    <ChInput
      v-model="email"
      :label="$t('auth.email')"
      class="mb-4"
      :placeholder="$t('admin.accounts.email')"
      required
    />
    <ChButton class="mb-4" :disabled="!checkEmail" type="submit">{{ $t('admin.accounts.create') }}</ChButton>
  </form>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import { useOrganizationStore } from '@/stores/organizations'
  import { ChButton, ChInput } from '@chatlyncom/chatlyn-ui-components'
  import { showErrors } from '@/utils/errors'
  import { toast } from 'vue3-toastify'
  import { useI18n } from 'vue-i18n'
  import { useNestStore } from '@/stores/nests'
  import { DEFAULT_FIELD_OF_ACTIVITY, DEFAULT_ORGANIZATION_NAME } from '@/constants/organizations'
  import { DEFAULT_NEST_NAME } from '@/constants/nests'

  const { t } = useI18n()
  const authStore = useAuthStore()
  const nestStore = useNestStore()
  const organizationStore = useOrganizationStore()

  const emit = defineEmits(['success'])

  const email = ref<string>('')

  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const checkEmail = computed(() => {
    return emailRegex.test(email.value)
  })

  const create = async () => {
    try {
      const user = await authStore.actions.createUser({
        email: email.value,
        isSuperAdmin: false,
      })
      const organization = await organizationStore.actions.createOrganization({
        organizationName: DEFAULT_ORGANIZATION_NAME,
        fieldOfActivity: DEFAULT_FIELD_OF_ACTIVITY,
        ownerId: user.userId,
      })
      if (organization) {
        await nestStore.actions.createNest(organization.organizationId, {
          title: DEFAULT_NEST_NAME,
        })
      } else {
        throw new Error(t('admin.accounts.create-organization-error'))
      }

      emit('success')

      toast.success(t('admin.accounts.created'))
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }
</script>

<style lang="scss" scoped>
  .auth-form {
    @apply flex flex-col content-stretch justify-center bg-white dark:bg-gray-800 px-4;
  }
</style>
