export default (state: any) => {
  const user = () => {
    return state.user
  }

  const users = () => {
    return state.users
  }

  return {
    user,
    users,
  }
}
