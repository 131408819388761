<template>
  <div class="crawler-actions">
    <ChInput
      v-model="value"
      class="crawler-actions__field"
      icon="search"
      :placeholder="$t('crawler-view.urls.actions.search')"
      :size="FieldSize.MD"
    />
    <ChButton
      class="crawler-actions__btn"
      prepend-icon="play-outlined"
      :color="ColorScheme.SECONDARY"
      :size="ButtonSize.SM"
      :disabled="empty"
      @click="rerun"
      >{{ $t('crawler-view.urls.actions.btn.rerun') }}</ChButton
    >
    <ChButton
      class="crawler-actions__btn"
      prepend-icon="delete"
      :color="ColorScheme.ALERT"
      :size="ButtonSize.SM"
      :disabled="empty"
      @click="deleteSelected"
      >{{ $t('crawler-view.urls.actions.btn.delete') }}</ChButton
    >
    <ChButton
      class="crawler-actions__btn"
      prepend-icon="add"
      :color="ColorScheme.PRIMARY"
      :size="ButtonSize.SM"
      @click="addUrls"
      >{{ $t('crawler-view.urls.actions.btn.add') }}</ChButton
    >
  </div>
</template>

<script setup lang="ts">
  import { ButtonSize, ChButton, ChInput, ColorScheme, FieldSize } from '@chatlyncom/chatlyn-ui-components'

  const value = defineModel({ type: String, default: '' })

  defineProps({
    empty: {
      type: Boolean,
      default: true,
    },
  })

  const emit = defineEmits(['rerun', 'delete', 'add'])

  const rerun = () => {
    emit('rerun')
  }

  const deleteSelected = () => {
    emit('delete')
  }

  const addUrls = () => {
    emit('add')
  }
</script>

<style scoped lang="scss">
  .crawler-actions {
    @apply flex items-center mb-4;

    &__field {
      @apply mr-auto w-64;
    }

    &__btn {
      @apply ml-2;
    }
  }
</style>
