<template>
  <div :class="iconColor" class="icon" :style="iconStyle">
    <icon :width="size" :height="size" viewBox="0 0 24 24" />
  </div>
</template>

<script lang="ts" setup>
  import { computed, defineAsyncComponent } from 'vue'
  const props = defineProps({
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '16',
    },
    color: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'outline',
    },
  })

  const iconColor = props.color ? `text-${props.color}` : ''

  const iconStyle = computed(() => ({
    width: `${props.size}px`,
    height: `${props.size}px`,
  }))

  const icon = defineAsyncComponent(() => import(`../../../assets/icons/${props.icon}.svg`))
</script>

<style lang="scss" scoped>
  .icon {
    @apply inline-flex items-center justify-center;
  }
</style>
