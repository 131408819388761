import { toast } from 'vue3-toastify'
import auth from '@/api/authNew'
import { removeAuth, setAuth, setAuthTokens } from '@/utils/local-storage'
import { useRouter } from 'vue-router'
import { showErrors } from '@/utils/errors'

export default (mutations: any) => {
  const router = useRouter()
  const generateTokens = async (credentials: any) => {
    try {
      const { data } = await auth.generateTokens(credentials)
      setAuth(data)
      mutations.signIn()
      if (localStorage.getItem('isSuperAdmin') === 'true') {
        await router.push({ name: 'admin' })
      } else {
        await router.push({ name: 'home' })
      }
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const refreshTokens = async (tokens: any) => {
    try {
      const { data } = await auth.refreshTokens(tokens)
      setAuthTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (_) {
      await router.push({ name: 'auth' })
    }
  }

  const nullifyTokens = async (tokens: any | any) => {
    try {
      await auth.nullifyTokens({
        accessToken: `Bearer ${tokens.accessToken}`,
        refreshToken: `Bearer ${tokens.refreshToken}`,
      })
      removeAuth()
      await router.push({ name: 'auth' })
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const passwordReset = async (email: string) => {
    try {
      await auth.passwordReset(email)
      toast.success('Password reset email sent')
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const passwordResetComplete = async (credentials: any) => {
    try {
      await auth.passwordResetComplete(credentials)
      toast.success('Password reset complete')
      await router.push({ name: 'auth' })
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const resendVerificationEmail = async (email: string) => {
    try {
      await auth.resendVerificationEmail(email)
      toast.success('Verification email sent')
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const registration = async (credentials: any) => {
    try {
      await auth.registration(credentials)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const createUser = async (credentials: any) => {
    try {
      const { data } = await auth.createUser(credentials)

      return data
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  return {
    generateTokens,
    refreshTokens,
    nullifyTokens,
    passwordReset,
    passwordResetComplete,
    resendVerificationEmail,
    registration,
    createUser,
  }
}
