import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import routes from '@/infrastructure/config/router/routes'
import { loadLayoutMiddleware } from '@/middleware/loadLayoutMiddleware'

const routers = createRouter({
  history: import.meta.env.VITE_ENV === 'production' ? createWebHashHistory() : createWebHistory(),
  routes,
})

routers.beforeEach(loadLayoutMiddleware)

export default routers
