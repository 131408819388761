<template>
  <form @submit.prevent="signIn" class="auth-form">
    <div class="flex mb-12 relative">
      <img class="mx-auto" :width="40" :height="40" :src="imgLoader('logo', 'svg')" alt="logo" />
    </div>
    <ChInput v-model="email" :label="$t('auth.email')" class="mb-4" required />
    <ChInput v-model="password" :label="$t('auth.password')" class="mb-4" required :type="InputType.PASSWORD" />
    <ChButton class="mb-4" :disabled="!checkEmail" type="submit">
      {{ $t('auth.signIn') }}
    </ChButton>
    <ChButton :variant="ButtonVariant.TEXT" :to="{ name: 'reset' }">
      {{ $t('auth.forgot-password') }}
    </ChButton>
  </form>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import imgLoader from '@/utils/img-loader'
  import { ButtonVariant, ChButton, ChInput, InputType } from '@chatlyncom/chatlyn-ui-components'

  const authStore = useAuthStore()

  const email = ref<string>('')
  const password = ref<string>('')

  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const checkEmail = computed(() => {
    return emailRegex.test(email.value) && password.value.length > 7
  })

  const signIn = () => {
    authStore.actions.generateTokens({
      email: email.value,
      password: password.value,
    })
  }
</script>

<style lang="scss" scoped>
  .auth-form {
    @apply flex flex-col content-stretch justify-center w-full bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6;
  }
</style>
