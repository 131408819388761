<template>
  <div class="crawler-header">
    <ChIcon class="crawler-header__icon" icon="globe" :size="32" />
    <div class="crawler-header__info">
      <h2 class="crawler-header__name">{{ scraper?.title }}</h2>
      <span class="crawler-header__url">{{ scraper?.mainURL }}</span>
    </div>
    <div class="crawler-header__actions">
      <ChButton
        class="crawler-header__btn"
        prepend-icon="glasses"
        :color="ColorScheme.SECONDARY"
        @click="extractUrls"
        >{{ $t('crawler-view.header.auto') }}</ChButton
      >
      <ChButton class="crawler-header__btn" prepend-icon="play-outlined" :color="ColorScheme.PRIMARY" @click="run">{{
        $t('crawler-view.header.now')
      }}</ChButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useCrawlerStore } from '@/stores/crawler'
  import { computed } from 'vue'
  import { ChButton, ChIcon, ColorScheme } from '@chatlyncom/chatlyn-ui-components'

  const crawlerStore = useCrawlerStore()

  const emit = defineEmits(['extract-urls', 'run'])

  const scraper = computed(() => crawlerStore.getters.scraper())

  const run = () => {
    emit('run')
  }

  const extractUrls = () => {
    emit('extract-urls')
  }
</script>

<style scoped lang="scss">
  .crawler-header {
    @apply flex items-center mb-4;

    &__icon {
      @apply mr-4;
    }

    &__info {
      @apply flex-1 flex flex-wrap;
    }

    &__name {
      @apply w-full;
    }

    &__url {
      @apply text-xs text-gray-400;
    }

    &__actions {
      @apply flex items-center ml-auto space-x-2;
    }
  }

  .pulse {
    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 5px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
</style>
