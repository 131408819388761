import { getUserFromJWT } from '@/utils/parsers'

export const setAuth = (auth: any) => {
  localStorage.setItem('accessToken', auth.accessToken)
  localStorage.setItem('refreshToken', auth.refreshToken)
  localStorage.setItem('auth', 'true')
  localStorage.setItem('email', getUserFromJWT(auth.accessToken).email)
  localStorage.setItem('isSuperAdmin', getUserFromJWT(auth.accessToken).isSuperAdmin.toString())
  localStorage.setItem('userId', getUserFromJWT(auth.accessToken).userId.toString())
}

export const removeAuth = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('auth')
  localStorage.removeItem('email')
  localStorage.removeItem('userId')
  localStorage.removeItem('isSuperAdmin')
}

export const setAuthTokens = (auth: any) => {
  localStorage.setItem('accessToken', auth.accessToken)
  localStorage.setItem('refreshToken', auth.refreshToken)
}

export const getAuthTokens = () => {
  return {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  }
}

export const removeAuthTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

export const getItem = (key: string) => {
  return localStorage.getItem(key)
}

export const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value)
}
