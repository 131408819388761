import axios from 'axios'
import { addParams } from '@/utils/route-helpers'
import type { CreateNestDto } from '@/types/interfaces'

export default {
  fetchNests: (orgId: string) => {
    return axios.get(`/organizations/${orgId}/nests`)
  },
  fetchNest: (orgId: string, nestId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}`)
  },
  createNest: (orgId: number, data: CreateNestDto) => {
    return axios.post(`/organizations/${orgId}/nests`, data)
  },
  deleteNest: (orgId: string, nestId: string) => {
    return axios.delete(`/organizations/${orgId}/nests/${nestId}`)
  },
  deleteNestSoft: (orgId: string, nestId: string) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}/delete`)
  },
  restoreNestSoft: (orgId: string, nestId: string) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}/restore`)
  },
  fetchNestOptions: (orgId: string, nestId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/options`)
  },
  updateNestOptions: (orgId: string, nestId: string, data: any) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}`, data)
  },
  fetchSecret: (orgId: string, nestId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/secret`)
  },
  generateSecret: (orgId: string, nestId: string) => {
    return axios.post(`/organizations/${orgId}/nests/${nestId}/secret`)
  },
  verifySecret: (data: any) => {
    return axios.post(`/authorization/verify-secret-key`, data)
  },
  revokeSecret: (orgId: string, nestId: string) => {
    return axios.delete(`/organizations/${orgId}/nests/${nestId}/secret`)
  },
  fetchIntents: (orgId: string, nestId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/intents`)
  },
  fetchIntent: (orgId: string, nestId: string, intentId: number) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/intents/${intentId}`)
  },
  createIntent: (orgId: string, nestId: string, data: any) => {
    return axios.post(`/organizations/${orgId}/nests/${nestId}/intents`, data)
  },
  updateIntent: (orgId: string, nestId: string, data: any) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}/intents`, data)
  },
  deleteIntent: (orgId: string, nestId: string, intentId: number) => {
    return axios.delete(`/organizations/${orgId}/nests/${nestId}/intents/${intentId}`)
  },
  fetchFileUrl: (orgId: string, nestId: string, dataSourceId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/files/${dataSourceId}`)
  },
  uploadFile: (orgId: string, nestId: string, file: File) => {
    const formData = new FormData()

    formData.append('file', file)

    return axios.post(`/organizations/${orgId}/nests/${nestId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  updateFileTitle: (orgId: string, nestId: string, dataSourceId: string, title: string) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}/files/${dataSourceId}`, { title })
  },
  fetchText: (orgId: string, nestId: string, dataSourceId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/files/text/${dataSourceId}`)
  },
  uploadText: (orgId: string, nestId: string, data: any) => {
    return axios.post(`/organizations/${orgId}/nests/${nestId}/files/text`, data)
  },
  updateText: (orgId: string, nestId: string, dataSourceId: string, data: any) => {
    return axios.patch(`/organizations/${orgId}/nests/${nestId}/files/text/${dataSourceId}`, data)
  },
  processText: (orgId: string, nestId: string, dataSourceId: string) => {
    return axios.put(`/organizations/${orgId}/nests/${nestId}/files/process-draft/${dataSourceId}`)
  },
  regenerateDataSourceChunks: (orgId: string, nestId: string, dataSourceId: string) => {
    return axios.put(`/organizations/${orgId}/nests/${nestId}/files/regenerate-chunks/${dataSourceId}`)
  },
  fetchDataSources: (orgId: string, nestId: string, page: number, limit: number) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/data-sources?page=${page}&limit=${limit}`)
  },
  deleteDataSource: (orgId: string, nestId: string, dataSourceId: string) => {
    return axios.delete(`/organizations/${orgId}/nests/${nestId}/data-sources/${dataSourceId}`)
  },
  fetchDialog: (orgId: string, nestId: string, dialogId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/dialogs/${dialogId}`)
  },
  fetchTrainingDialog: (orgId: string, nestId: string) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/dialogs/training`)
  },
  fetchHistoryDialogs: (
    orgId: string,
    nestId: string,
    page: string | number,
    limit: string | number,
    name: string,
    identifier: string,
    dateFrom: string,
    dateTo: string,
  ) =>
    axios.get(
      addParams(
        `/organizations/${orgId}/nests/${nestId}/dialogs/?page=${page}&limit=${limit}`,
        {
          conversationStarter: name,
          guestIdentifier: identifier,
          dateFrom,
          dateTo,
        },
        '&',
      ),
    ),
  createDialog: (orgId: string, nestId: string, data: any) => {
    return axios.post(`/organizations/${orgId}/nests/${nestId}/dialogs`, data)
  },
  fetchInteractions: (orgId: string, nestId: string, dialogId: string, page: number) => {
    return axios.get(`/organizations/${orgId}/nests/${nestId}/dialogs/${dialogId}/interactions?limit=50&page=${page}`)
  },

  // fetchNestOptions: (nestId: string) => {
  //   return axios.get(`/nests/${nestId}/options`)
  // },
  getResources: (id: string) => {
    return axios.get(`/nests/${id}/data-sources?page=1&limit=10`)
  },
  upload: (nestId: string, file: any) => {
    return axios.post(`/nests/${nestId}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  createTextResource: (nestId: string, data: any) => {
    return axios.post(`/nests/${nestId}/files/text`, data)
  },
  getTextResource: (nestId: string, dataSourceId: string | number) => {
    return axios.get(`/nests/${nestId}/files/text/${dataSourceId}`)
  },
  updateTextResource: (nestId: string, dataSourceId: string, data: any) => {
    return axios.patch(`/nests/${nestId}/files/text/${dataSourceId}`, data)
  },
  updateTitleResource: (nestId: string, dataSourceId: string, title: string) => {
    return axios.patch(`/nests/${nestId}/files/${dataSourceId}`, { title })
  },
  webpage: (id: string, url: string) => {
    return axios.get(`/nests/${id}/data-sources/webpages`, {
      url,
    })
  },
  update: (id: string, data: any) => {
    return axios.post('/nests/' + id, data)
  },
  updateNest: (id: string, data: any) => {
    return axios.patch('/nests/' + id, data)
  },
  // Secret Key
  // generateSecret: (id: string) => {
  //   return axios.post(`/nests/${id}/secret`)
  // },
  getSecret: (id: string) => {
    return axios.get(`/nests/${id}/secret`)
  },
  // revokeSecret: (id: string) => {
  //   return axios.delete(`/nests/${id}/secret`)
  // },
  // Dialogs
  // createDialog: (id: string, data: any) => {
  //   return axios.post(`/nests/${id}/dialogs`, data)
  // },
  // createIntent: (nestId: any, newIntent: any) => {
  //   return axios.post(`/nests/${nestId}/intents`, newIntent)
  // },
  getIntents: (nestId: string) => {
    return axios.get(`/nests/${nestId}/intents`)
  },
  getIntent: (nestId: string, intentId: string) => {
    return axios.get(`/nests/${nestId}/intents/${intentId}`)
  },
  // deleteIntent: (nestId: string, intentId: string) => {
  //   return axios.delete(`/nests/${nestId}/intents/${intentId}`)
  // },
  // updateIntent: (nestId: string, intentId: string, data: any) => {
  //   return axios.patch(`/nests/${nestId}/intents/${intentId}`, data)
  // },
  getDataSourceFilePath: (nestId: string | string[], dataSourceId: string) => {
    return axios.get(`/nests/${nestId}/files/${dataSourceId}`)
  },
  deleteResource: (nestId: string, dataSourceId: any) => {
    return axios.delete(`/nests/${nestId}/data-sources/${dataSourceId}`)
  },
  getDialogs: (id: string) => {
    return axios.get(`/nests/${id}/dialogs`)
  },
  getHistoryDialogs: (
    id: string,
    page: string | number,
    limit: string | number,
    name: string,
    identifier: string,
    dateFrom: string,
    dateTo: string,
  ) =>
    axios.get(
      addParams(
        `/nests/${id}/dialogs?page=${page}&limit=${limit}`,
        {
          conversationStarter: name,
          guestIdentifier: identifier,
          dateFrom,
          dateTo,
        },
        '&',
      ),
    ),
  getInteractions: (nestId: string, dialogId: string, page: number) => {
    return axios.get(`/nests/${nestId}/dialogs/${dialogId}/interactions?limit=50&page=${page}`)
  },
  getDialog: (nestId: string, dialogId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/${dialogId}`)
  },
  getTrainingDialog: (nestId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/training`)
  },
  askQuestion: (data: any) => {
    return axios.post('/chatbot/ask', data)
  },
}
