import { toast } from 'vue3-toastify'
import organizations from '@/api/nests/organizations'
import type { CreateOrganizationDto, OrganizationResponse } from '@/types/interfaces'

export default (mutations: any) => {
  const fetchOrganizationsApiKeys = async (organizationId: string) => {
    try {
      const { data } = await organizations.fetchOrganizationsApiKeys(organizationId)
      mutations.setOrganizationsApiKeys(data)

      const apiKey = data.items.find((item: any) => item.active)

      mutations.setApiKey(apiKey ? apiKey : null)

      return data.items
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const createOrganizationApiKey = async (organizationId: string) => {
    try {
      const { data } = await organizations.createOrganizationsApiKey(organizationId)

      mutations.setApiKey(data)

      return data
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const deleteOrganizationApiKey = async (organizationId: string, apiKeyId: string) => {
    try {
      await organizations.deleteOrganizationsApiKey(organizationId, apiKeyId)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchOrganizations = async () => {
    try {
      const { data } = await organizations.fetchOrganizations()
      mutations.setOrganizations(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchOrganization = async (organizationId: string) => {
    try {
      const { data } = await organizations.fetchOrganization(organizationId)
      mutations.setOrganization(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const createOrganization = async (
    newOrganization: CreateOrganizationDto,
  ): Promise<OrganizationResponse | undefined> => {
    try {
      const { data } = await organizations.createOrganization(newOrganization)

      return data
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const deleteOrganization = async (organizationId: string) => {
    try {
      await organizations.deleteOrganization(organizationId)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateOrganization = async (organizationId: string, data: any) => {
    try {
      await organizations.updateOrganization(organizationId, data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchOrganizationsApiKeys,
    createOrganizationApiKey,
    deleteOrganizationApiKey,
    fetchOrganizations,
    fetchOrganization,
    createOrganization,
    deleteOrganization,
    updateOrganization,
  }
}
