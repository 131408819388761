import { reactive } from 'vue'

export default reactive<any>({
  auth: false,
  user: {},
  users: {
    count: 0,
    items: [],
  },
})
