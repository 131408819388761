<template>
  <main class="main">
    <div class="container">
      <div class="crawler-view">
        <CrawlerHeader @extract-urls="toggleExtractUrlsDialog" @run="run" />
        <AiTabs :current-tab="currentTab" :items="tabs" @select-tab="changeTab">
          <CrawlerUrls v-if="currentTab === 0" />
          <CrawlerConfig v-if="currentTab === 1" />
        </AiTabs>
      </div>
    </div>
    <AiDialog v-if="extractUrlsDialogShowing" title="Url Selection" size="lg" @close="toggleExtractUrlsDialog">
      <div v-if="scraper" class="detect-urls">
        <ThirdStep config-page :url="scraper.mainURL" @save="saveExtractedUrls" />
      </div>
    </AiDialog>
  </main>
</template>

<script lang="ts" setup>
  import { useCrawlerStore } from '@/stores/crawler'
  import { useRouteParams } from '@/composables/route-params'
  import { computed, onMounted, ref } from 'vue'
  import CrawlerHeader from '@/components/app/crawler/view/CrawlerHeader.vue'
  import AiTabs from '@/components/ui/tabs/AiTabs.vue'
  import { useI18n } from 'vue-i18n'
  import CrawlerUrls from '@/components/app/crawler/view/urls/CrawlerUrls.vue'
  import CrawlerConfig from '@/components/app/crawler/view/config/CrawlerConfig.vue'
  import AiDialog from '@/components/ui/AiDialog.vue'
  import ThirdStep from '@/components/app/crawler/dialog/ThirdStep.vue'

  const { t } = useI18n()
  const crawlerStore = useCrawlerStore()

  const { query, params, orgId, nestId } = useRouteParams()

  const currentTab = ref(0)
  const extractUrlsDialogShowing = ref(false)

  const tabs = [{ label: t('crawler.tabs.urls') }, { label: t('crawler.tabs.configuration') }]

  const scraper = computed(() => crawlerStore.getters.scraper())
  const webpages = computed(() => crawlerStore.getters.webpages())
  const selectedWebpages = computed(() => crawlerStore.getters.selectedUrls())

  const toggleExtractUrlsDialog = () => {
    if (scraper.value) {
      crawlerStore.actions.extractUrls(orgId.value, nestId.value, {
        url: scraper.value.mainURL,
        crawlingDepth: scraper.value.crawlingDepth,
        crawlingSelectors: scraper.value.crawlingSelectors,
      })
    }
    extractUrlsDialogShowing.value = !extractUrlsDialogShowing.value
  }

  const hideExtractUrlsDialog = () => {
    extractUrlsDialogShowing.value = false
  }

  const fetchData = async () => {
    await crawlerStore.actions.fetchScraper(orgId.value, nestId.value, params.scraperId as string)
    await crawlerStore.actions.fetchScraperWebpages(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      Number(query.value.page) | 1,
    )
  }

  const saveExtractedUrls = async (selectors: string) => {
    if (scraper.value) {
      crawlerStore.mutations.setScraper({
        ...scraper.value,
        crawlingSelectors: selectors,
      })
    }
    await crawlerStore.actions.addPages(orgId.value, nestId.value, params.scraperId as string, getMatchingUrls())
    hideExtractUrlsDialog()
    await fetchData()
  }

  const getMatchingUrls = () => {
    const matchedUrls: string[] = []

    selectedWebpages.value.forEach((id: number) => {
      const matchingUrl = crawlerStore.getters.extractedUrls().find((url) => url.id === id)

      if (matchingUrl) {
        matchedUrls.push(matchingUrl.url)
      }
    })

    return matchedUrls
  }

  const run = async () => {
    await crawlerStore.actions.runScraper(
      orgId.value,
      nestId.value,
      params.scraperId as string,
      webpages.value.items.map((page) => page.webpageId),
    )
    setTimeout(() => {
      crawlerStore.actions.fetchScraperWebpages(
        orgId.value,
        nestId.value,
        params.scraperId as string,
        Number(query.value.page) | 1,
      )
    }, 5000)
  }

  const changeTab = (tabIndex: number) => {
    currentTab.value = tabIndex
  }

  onMounted(() => {
    fetchData()
  })
</script>

<style lang="scss" scoped>
  .crawler-view {
    @apply bg-white dark:bg-gray-800 flex flex-col w-full h-full rounded-lg border dark:border-gray-700 overflow-hidden p-4;
  }

  .detect-urls {
    @apply p-4;
  }
</style>
