<template>
  <form @submit.prevent="resetComplete" class="auth-form">
    <div class="flex mb-12 relative">
      <img class="mx-auto" :width="40" :height="40" :src="imgLoader('logo', 'svg')" alt="logo" />
    </div>
    <ChInput v-model="password" :label="$t('auth.new-password')" class="mb-4" required :type="InputType.PASSWORD" />
    <ChButton :size="ButtonSize.LG" :disabled="password.length < 8" type="submit">
      {{ $t('auth.reset-password') }}</ChButton
    >
  </form>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import imgLoader from '@/utils/img-loader'
  import { useRoute } from 'vue-router'
  import { ButtonSize, ChButton, ChInput, InputType } from '@chatlyncom/chatlyn-ui-components'

  const route = useRoute()
  const authStore = useAuthStore()

  const password = ref<string>('')

  const resetComplete = () => {
    authStore.actions.passwordResetComplete({
      password: password.value,
      token: route.query.token as string,
    })
  }
</script>

<style lang="scss" scoped>
  .auth-form {
    @apply flex flex-col content-stretch justify-center w-full bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6;
  }
</style>
