<template>
  <div class="welcome-wrapper">
    <img class="welcome-wrapper__img" src="@/assets/online-video.png" alt="welcome page" />
    <h1 class="welcome-wrapper__title text-3xl mb-4">
      {{ $t('home.welcome.title') }}
    </h1>
    <p class="text-center text-gray-400 text-sm mb-4">
      {{ $t('home.welcome.description') }}
    </p>
    <ChButton prepend-icon="editor" @click="toggleNestDialog">{{ $t('home.welcome.create') }}</ChButton>
  </div>
</template>

<script lang="ts" setup>
  import { useGlobalStore } from '@/stores/global'
  import { ChButton } from '@chatlyncom/chatlyn-ui-components'

  const globalStore = useGlobalStore()

  const toggleNestDialog = () => globalStore.mutations.toggleNestDialog()
</script>

<style lang="scss" scoped>
  .welcome-wrapper {
    max-width: 750px;

    @apply w-full flex flex-col items-center justify-center rounded-lg p-12 bg-white dark:bg-gray-800 shadow-xl;

    &__img {
      @apply w-1/2 mb-4;
    }
  }
</style>
