import { createApp } from 'vue'
// import { store } from '@/store'
import router from '@/infrastructure/config/router'
import i18n from '@/infrastructure/config/i18n'
import sharedComponents from '@/infrastructure/config/shared-components'
import interceptorsSetup from '@/infrastructure/config/axios/interceptors'
import App from '@/App.vue'

import { createPinia } from 'pinia'
import VueDatePicker from '@vuepic/vue-datepicker'
import * as Sentry from '@sentry/vue'
import CronCorePlugin from '@vue-js-cron/core'
// import { Integrations, createRouterInstrumentation } from '@sentry/tracing'

import '@chatlyncom/chatlyn-ui-components/style.css'
import '@vueform/slider/themes/default.css'
import '@vueform/slider/themes/default.scss'
import '@vuepic/vue-datepicker/dist/main.css'

import 'animate.css'
import 'vue3-toastify/dist/index.css'
import '@/assets/styles/tailwind.scss'
import '@/assets/styles/index.scss'

const app = createApp(App)

const pinia = createPinia()

app.component('VueDatePicker', VueDatePicker)

app.use(pinia)
app.use(i18n)
app.use(router)
app.use(CronCorePlugin)
// app.use(store)
app.use(sharedComponents)

Sentry.init({
  release: 'my-project-name@1',
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    // new Integrations.BrowserTracing({
    //   routingInstrumentation: createRouterInstrumentation(router),
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/staging\.chatlyn\.com\/chatlynai#/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

interceptorsSetup()

app.mount('#app')
