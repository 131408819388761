<template>
  <div v-element-size="onResizeWrapper" ref="wrapper" class="training-answer" :class="{ 'w-full': edit }">
    <p v-if="!edit" :class="{ selected: isSelected }" class="training-answer__text break-all">
      {{ answer.text }}
    </p>
    <span v-element-size="onResize" class="training-answer__hidden break-all">{{ edit ? answerEdit.text : '' }}</span>
    <div v-if="edit" class="training-answer__edit">
      <AiTextarea
        v-model="answerEdit.text"
        class="training-answer__field"
        size="lg"
        :style="{
          minWidth: titleWidth + 'px',
          maxWidth: wrapperWidth + 'px',
        }"
      />
      <ChButton :size="ButtonSize.SM" :color="ColorScheme.ACCENT" @click="saveEditedAnswer">{{
        $t('improve-dialog.confirm')
      }}</ChButton>
      <ChButton
        icon="dismiss"
        :size="ButtonSize.SM"
        :variant="ButtonVariant.CLEAR"
        :color="ColorScheme.SECONDARY"
        @click="toggleEdit"
      />
    </div>
    <ChButton
      v-if="!edit"
      class="training-answer__btn"
      icon="edit"
      :variant="ButtonVariant.CLEAR"
      :color="ColorScheme.SECONDARY"
      rounded
      @click="toggleEdit"
    />
  </div>
</template>

<script setup lang="ts">
  import type { Answer } from '@/types/interfaces'
  import { computed, onMounted, type PropType, reactive, ref } from 'vue'
  import { vElementSize } from '@vueuse/components'
  import AiTextarea from '@/components/ui/AiTextarea.vue'
  import { useChatStore } from '@/stores/chat'
  import { useElementSize } from '@vueuse/core'
  import { ButtonSize, ButtonVariant, ChButton, ColorScheme } from '@chatlyncom/chatlyn-ui-components'

  const chatStore = useChatStore()

  const props = defineProps({
    answer: {
      type: Object as PropType<Answer>,
      required: true,
    },
    selected: {
      type: Object as PropType<Answer | null>,
      default: null,
    },
  })

  const emits = defineEmits(['update'])

  const generateId = (): string => {
    return Math.random().toString(36).substring(7)
  }

  const wrapper = ref<HTMLElement | null>(null)
  const answerEdit = reactive({
    text: '',
    ownId: '',
    answerId: 0,
    reaction: '',
  })

  const edit = ref(false)
  const titleWidth = ref(0)
  const wrapperWidth = ref(0)

  const wrapperParams = useElementSize(wrapper)

  const isSelected = computed(() => props.selected?.ownId === props.answer.ownId)

  const toggleEdit = () => {
    edit.value = !edit.value
  }

  const onResize = ({ width }: { width: number }) => {
    wrapperWidth.value = wrapperParams.width.value - 128
    if (width < 184) {
      titleWidth.value = 232
    }
    if (width >= 184 && width < wrapperWidth.value) {
      titleWidth.value = width + 24
    }
    if (width > wrapperWidth.value) {
      titleWidth.value = wrapperWidth.value
    }
  }

  const onResizeWrapper = ({ width }: { width: number }) => {
    wrapperWidth.value = width - 128
  }

  onMounted(() => {
    answerEdit.text = props.answer.text
    answerEdit.ownId = props.answer.ownId || generateId()
    answerEdit.answerId = props.answer.answerId
    answerEdit.reaction = props.answer.reaction
  })

  const saveEditedAnswer = () => {
    const answers = chatStore.getters.answers()
    const index = answers.findIndex((answer: Answer) => answer.answerId === answerEdit.answerId)
    answers[index].text = answerEdit.text
    answers[index].ownId = answerEdit.ownId
    chatStore.mutations.setAnswers(answers)
    toggleEdit()
    emits('update', answers[index])
  }
</script>

<style scoped lang="scss">
  .training-answer {
    @apply relative;

    &__text {
      @apply text-sm leading-6 break-words p-2.5 rounded transition-colors duration-200 bg-gray-100 text-gray-500 dark:bg-gray-900 dark:text-gray-400;

      &.selected {
        @apply bg-violet-100 text-violet-600 dark:bg-violet-700/70 dark:text-violet-100;
      }
    }

    &__btn {
      @apply opacity-0 transition-all absolute flex items-center -right-12 z-10 top-1/2 transform -translate-y-1/2;
    }

    &__hidden {
      @apply -top-4 -left-4 max-h-0 opacity-0 absolute pointer-events-none text-sm;
    }

    &__edit {
      @apply min-w-48 flex items-center space-x-2;

      max-width: calc(100% - 8rem);
    }

    &:hover {
      .training-answer__btn {
        @apply opacity-100;
      }
    }
  }
</style>
