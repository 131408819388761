import type { CrawlingState, Webpage } from '@/types/interfaces'

export default (state: CrawlingState) => {
  const featureFlags = () => {
    return state.featureFlags
  }

  const extractedUrls = () => {
    return state.extractedUrls.map((url: string, index: number) => {
      return {
        id: index + 2,
        url,
      }
    })
  }

  const urlsTypes = () => {
    return state.urlsTypes
  }

  const selectedUrls = () => {
    return state.selectedUrls
  }

  const webScrapersResponse = () => {
    return state.webScrapers
  }

  const webScrapers = () => {
    return state.webScrapers.items.map((scraper: any) => {
      return {
        ...scraper,
        type: 'WEBPAGE',
      }
    })
  }

  const extractingUrlsFlag = () => {
    return state.flags.extractingUrls
  }

  const scraper = () => {
    return state.scraper
  }

  const webpages = () => {
    return state.webpages
  }

  const formattedWebpages = () => {
    const items = state.webpages.items.map((webpage: Webpage) => ({
      source: webpage.dataSource.source,
      webpageId: webpage.webpageId,
      status: webpage.dataSource.status,
      updatedAt: webpage.updatedAt,
    }))

    return {
      items,
      count: state.webpages.count,
    }
  }

  return {
    featureFlags,
    extractedUrls,
    urlsTypes,
    selectedUrls,
    webScrapersResponse,
    webScrapers,
    extractingUrlsFlag,
    scraper,
    webpages,
    formattedWebpages,
  }
}
