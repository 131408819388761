<template>
  <div class="flex items-center flex-wrap p-4 gap-4">
    <div v-for="(_, index) in urls" :key="index" class="flex items-center w-full gap-2">
      <ChInput
        v-model="urls[index].url"
        class="flex-1"
        placeholder="Enter URL"
        prepend-icon="link"
        :error="urls[index].error"
      />
      <ChButton
        icon="delete"
        :disabled="urls.length === 1"
        :variant="ButtonVariant.CLEAR"
        :color="ColorScheme.ALERT"
        :size="ButtonSize.SM"
        @click="deleteUrl(index)"
      />
    </div>
    <ChButton icon="add" :variant="ButtonVariant.CLEAR" :size="ButtonSize.SM" @click="addNewUrl" />
    <ChButton
      class="ml-auto"
      prepend-icon="dismiss"
      :color="ColorScheme.SECONDARY"
      :size="ButtonSize.SM"
      @click="cancel"
    >
      {{ t('crawler-view.add-urls.cancel') }}
    </ChButton>
    <ChButton
      prepend-icon="add"
      :color="ColorScheme.PRIMARY"
      :size="ButtonSize.SM"
      :disabled="!isValid"
      @click="addUrls"
    >
      {{ t('crawler-view.add-urls.add') }}
    </ChButton>
  </div>
</template>

<script setup lang="ts">
  import { ButtonSize, ButtonVariant, ChButton, ChInput, ColorScheme } from '@chatlyncom/chatlyn-ui-components'
  import { computed, reactive, ref, watch } from 'vue'
  import { useCrawlerStore } from '@/stores/crawler'
  import { useI18n } from 'vue-i18n'

  interface Url {
    url: string
    error: string
  }

  const { t } = useI18n()
  const crawlerStore = useCrawlerStore()
  const emit = defineEmits(['add', 'cancel'])

  const urls = reactive<Url[]>([
    {
      url: '',
      error: '',
    },
  ])

  const isValid = ref<boolean>(false)

  const scraper = computed(() => crawlerStore.getters.scraper())
  const mainUrl = computed(() => scraper.value?.mainURL)

  const validateUrl = (url: string): string => {
    if (!mainUrl.value) {
      return ''
    }

    try {
      if (url.startsWith('/')) {
        return ''
      }

      if (url === mainUrl.value) {
        return t('crawler-view.add-urls.errors.same-as-main-url')
      }

      if (!url.startsWith(mainUrl.value)) {
        return t('crawler-view.add-urls.errors.invalid-domain')
      }

      return ''
    } catch {
      return t('crawler-view.add-urls.errors.invalid-format')
    }
  }

  const addNewUrl = () => {
    urls.push({
      url: '',
      error: '',
    })
  }

  const deleteUrl = (index: number) => {
    urls.splice(index, 1)
  }

  const cancel = () => {
    emit('cancel')
  }

  const addUrls = () => {
    const processedUrls = urls
      .filter((item: Url) => item.url.length > 0)
      .map((item: Url) => {
        if (item.url.startsWith('/') && mainUrl.value) {
          const base = new URL(mainUrl.value)
          const basePath = base.pathname.endsWith('/') ? base.pathname : base.pathname + '/'
          const relativePath = item.url.startsWith('/') ? item.url.substring(1) : item.url

          base.pathname = basePath + relativePath
          item.url = base.href
        }
        return item
      })

    const validUrls = processedUrls.filter((item: Url) => item.error === '').map((item) => item.url)

    if (validUrls.length > 0) {
      emit('add', validUrls)
    }
  }

  watch(
    urls,
    (newUrls) => {
      isValid.value = newUrls.length > 0 && newUrls.every((item) => item.error === '')

      newUrls.forEach((item) => {
        const errorMessage = validateUrl(item.url)
        if (!item.url) {
          item.error = t('crawler-view.add-urls.errors.empty')
        } else {
          item.error = errorMessage
        }
      })
    },
    { deep: true },
  )
</script>
