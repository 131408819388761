<template>
  <form @submit.prevent="signUp" class="d-flex flex-column">
    <div class="d-flex mb-12 position-relative">
      <img class="mx-auto" :width="90" :height="90" src="@/assets/logo.svg" alt="logo" />
    </div>
    <v-label class="mb-2 text-gray-400">{{ $t('auth.email') }}</v-label>
    <VTextField v-model="email" class="mb-4" required hide-details="auto"></VTextField>
    <v-label class="mb-2 text-gray-400">{{ $t('auth.password') }}</v-label>
    <VTextField v-model="password" required hide-details="auto" type="password" class="pwdInput mb-8"></VTextField>
    <v-btn :disabled="!checkEmail" size="large" color="primary" :block="true" type="submit" :flat="true">{{
      $t('auth.signUp')
    }}</v-btn>
    <v-btn :to="{ name: 'reset' }" variant="plain" color="primary" class="mx-auto mt-4">Forgot your password? ></v-btn>
  </form>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'

  const authStore = useAuthStore()

  const email = ref<string>('')
  const password = ref<string>('')

  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const checkEmail = computed(() => {
    return emailRegex.test(email.value) && password.value.length > 7
  })

  const signUp = () => {
    authStore.actions.registration({
      user: {
        email: email.value,
      },
      password: password.value,
    })
  }
</script>

<style lang="scss" scoped></style>
