<template>
  <div class="weekly-schedule">
    <span class="weekly-schedule__title">{{ $t('crawler-dialog.second-step.executions.weekly.days') }}</span>
    <ChCheckbox
      v-for="day in daysArray"
      v-model="days"
      multiple
      class="weekly-schedule__checkbox"
      :key="day.value"
      :id="day.name"
      :value="day.value"
      :label="day.name"
    />
    <span class="weekly-schedule__title">{{ $t('crawler-dialog.second-step.executions.weekly.time') }}</span>
    <div class="weekly-schedule__time">
      <ChCombobox class="weekly-schedule__input" v-model="hour" :options="hoursArray" />
      <ChCombobox class="weekly-schedule__input" v-model="min" :options="minutesArray" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ChCheckbox, ChCombobox } from '@chatlyncom/chatlyn-ui-components'

  const days = defineModel('days', {
    default: [],
  })
  const hour = defineModel('hour', {
    default: '10',
  })
  const min = defineModel('min', {
    default: '00',
  })

  const hoursArray = Array.from({ length: 24 }, (_, i) => ({
    name: i < 10 ? `0${i}` : `${i}`,
    value: i < 10 ? `0${i}` : `${i}`,
  }))

  const minutesArray = Array.from({ length: 60 }, (_, i) => ({
    name: i < 10 ? `0${i}` : `${i}`,
    value: i < 10 ? `0${i}` : `${i}`,
  }))

  const daysArray = [
    {
      name: 'Sunday',
      value: '0',
    },
    {
      name: 'Monday',
      value: '1',
    },
    {
      name: 'Tuesday',
      value: '2',
    },
    {
      name: 'Wednesday',
      value: '3',
    },
    {
      name: 'Thursday',
      value: '4',
    },
    {
      name: 'Friday',
      value: '5',
    },
    {
      name: 'Saturday',
      value: '6',
    },
  ]
</script>

<style scoped lang="scss">
  .weekly-schedule {
    @apply flex flex-wrap w-full;

    &__title {
      @apply w-full mb-2 dark:text-gray-300;
    }

    &__checkbox {
      @apply mr-4 mb-2 w-1/4;
    }

    &__time {
      @apply w-full flex;
    }

    &__input {
      @apply flex-1 dark:text-gray-400;

      &:first-of-type {
        @apply mr-4;
      }
    }
  }
</style>
