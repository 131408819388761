<template>
  <main class="main">
    <div class="container flex flex-wrap content-start">
      <InfoBox
        :title="resourceTitle"
        :description="$t('nest.info-box.description')"
        img-name="product-review"
        info-box-name="resourceInfoBox"
        route-name="resources"
      >
        <template #default>
          <div class="flex mt-4 gap-2">
            <ChButton prepend-icon="arrow-upload" @click="toggleResourceDialog">{{
              $t('nest.info-box.upload')
            }}</ChButton>
            <ChButton prepend-icon="text-edit-style" @click="toggleResourceEditorDialog">{{
              $t('nest.info-box.editor')
            }}</ChButton>
            <ChButton v-if="webScrapingEnabled" prepend-icon="globe-add" @click="toggleWebCrawlerDialog">{{
              $t('web-crawler-dialog.btn')
            }}</ChButton>
          </div>
        </template>
      </InfoBox>
      <div v-if="records.length > 0" class="w-full space-y-2">
        <ResourceCard
          v-for="resource in records"
          :key="resource.dataSourceId || resource.webScraperId"
          :resource="resource"
        />
      </div>
      <EmptyState v-else img-name="NoDocuments" :title="$t('nest.empty')" />
      <ResourcePagination :page="pagination.general" :limit="limit" :count="totalCount" @update:page="setPage" />
    </div>
  </main>
</template>

<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useResourceStore } from '@/stores/resources'
  import { useNestStore } from '@/stores/nests'
  import { useGlobalStore } from '@/stores/global'
  import ResourceCard from '@/components/app/resources/ResourceCard.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import ResourcePagination from '@/components/app/resources/ResourcePagination.vue'
  import { useRouteParams } from '@/composables/route-params'
  import { useCrawlerStore } from '@/stores/crawler'
  import { ChButton } from '@chatlyncom/chatlyn-ui-components'
  import InfoBox from '@/components/app/global/InfoBox.vue'
  import type { FeatureFlag } from '@/types/interfaces'
  import { ResourceStatusEnums } from '@/types/enums'

  const { t } = useI18n()
  const route = useRoute()
  const globalStore = useGlobalStore()
  const resourceStore = useResourceStore()
  const nestStore = useNestStore()
  const crawlerStore = useCrawlerStore()

  const { orgId, nestId } = useRouteParams()

  const interval = ref<any>(null)
  const webScrapersOffset = ref(0)
  const pagination = reactive({
    general: 1,
    resources: 1,
    webScrapers: 1,
  })
  const limit = 50

  const resources = computed(() => resourceStore.getters.resourcesResponse())
  const webScrapers = computed(() => crawlerStore.getters.webScrapersResponse())
  const totalCount = computed(() => resources.value.count + webScrapers.value.count)
  const featureFlags = computed(() => crawlerStore.getters.featureFlags())
  const records = computed(() => {
    const records: any[] = []

    const limitPerPage = limit
    const offset = (pagination.general - 1) * limitPerPage

    const firstRemaining = Math.max(0, resources.value.count - offset)
    const firstCount = Math.min(firstRemaining, limitPerPage)

    if (firstCount > 0) {
      records.push(...resources.value.items)
    }

    if (webScrapingEnabled.value && records.length < limitPerPage) {
      const remainingSpace = limitPerPage - records.length
      const secondCount = Math.min(remainingSpace, webScrapers.value.items.length)

      if (secondCount > 0 && webScrapersOffset.value === 0) {
        records.push(...webScrapers.value.items.slice(0, secondCount))
      } else {
        records.push(...webScrapers.value.items.slice(webScrapersOffset.value, secondCount))
      }
    }

    return records
  })

  const webScrapingEnabled = computed(() =>
    featureFlags.value.some((flag: FeatureFlag) => flag.value === 'web-scraping'),
  )

  const resourceTitle = computed(() => {
    const resourceCount = resources.value.count + webScrapers.value.count

    return resourceCount > 0
      ? `${resourceCount} ${resourceCount > 1 ? t('nest.info-box.title-plural') : t('nest.info-box.title-single')}`
      : t('nest.info-box.title-empty')
  })

  const toggleResourceDialog = () => globalStore.mutations.toggleResourceDialog()

  const toggleWebCrawlerDialog = () => globalStore.mutations.toggleWebCrawlerDialog()

  const toggleResourceEditorDialog = () => {
    globalStore.mutations.toggleResourceEditorDialog()
    resourceStore.mutations.setResource(null)
  }

  const setPage = (page: number) => {
    pagination.general = page

    const offset = (page - 1) * limit

    const firstRemaining = Math.max(0, resources.value.count - offset)
    const firstCount = Math.min(firstRemaining, limit)

    const secondOffset = Math.max(0, offset - resources.value.count)
    webScrapersOffset.value = Math.max(0, offset - resources.value.count)
    const secondCount = limit - firstCount

    if (firstCount > 0) {
      pagination.resources = Math.ceil((offset + firstCount) / limit)
      fetchResources()
    }

    if (webScrapingEnabled.value && secondCount > 0 && secondCount > limit) {
      pagination.webScrapers = Math.ceil((secondOffset + secondCount) / limit)
      fetchWebScrapers()
    }
  }

  const fetchResources = async () => {
    await resourceStore.actions.fetchResources({
      organizationId: orgId.value,
      nestId: nestId.value,
      page: pagination.resources,
      limit,
    })
  }

  const fetchWebScrapers = async () => {
    await crawlerStore.actions.fetchWebScrapers({
      organizationId: orgId.value,
      nestId: nestId.value,
      page: pagination.webScrapers,
      limit,
    })
  }

  const fetchData = async () => {
    await nestStore.actions.fetchNest(orgId.value, nestId.value)
    await crawlerStore.actions.fetchFeatureFlags(orgId.value)
    await fetchResources()
    if (webScrapingEnabled.value) {
      await fetchWebScrapers()
    }
  }

  watch(
    () => [route.params.orgId, route.params.nestId],
    () => {
      if (
        route.name === 'nest' ||
        route.name === 'history' ||
        route.name === 'testing' ||
        route.name === 'configuration' ||
        route.name === 'training'
      ) {
        fetchData()
      }
    },
  )

  onMounted(() => {
    fetchData()

    interval.value = setInterval(() => {
      const hasInProgressOrPending = resources.value.items.some(
        (resource: any) =>
          resource.status === ResourceStatusEnums.IN_PROGRESS || resource.status === ResourceStatusEnums.PENDING,
      )

      if (hasInProgressOrPending) {
        fetchData()
      }
    }, 5000)
  })

  onUnmounted(() => {
    clearInterval(interval.value)
  })
</script>

<style lang="scss" scoped></style>
