<template>
  <div class="training-save">
    <div class="training-save__header">
      <p class="training-save__header-question">{{ intent.question }}</p>
      <ChButton
        :color="ColorScheme.ALERT"
        :variant="ButtonVariant.SMOOTH"
        prepend-icon="delete"
        @click="toggleDeleteDialog"
        >{{ $t('training.content.delete') }}</ChButton
      >
    </div>
    <div class="training-save__response">
      <p class="training-save__response-title">
        {{ $t('training.content.save') }}
      </p>
      <p class="training-save__response-answer">{{ intent.answer }}</p>
      <ChButton :variant="ButtonVariant.SMOOTH" prepend-icon="undo" @click="resetIntent">{{
        $t('training.content.reset')
      }}</ChButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useGlobalStore } from '@/stores/global'
  import { useIntentStore } from '@/stores/intents'
  import { computed } from 'vue'
  import type { Intent } from '@/types/interfaces'
  import { useRouteParams } from '@/composables/route-params'
  import { useChatStore } from '@/stores/chat'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useNestStore } from '@/stores/nests'
  import { ButtonVariant, ChButton, ColorScheme } from '@chatlyncom/chatlyn-ui-components'

  const chatStore = useChatStore()
  const nestStore = useNestStore()
  const globalStore = useGlobalStore()
  const intentStore = useIntentStore()
  const dialogStore = useDialogsStore()

  const { orgId, nestId } = useRouteParams()

  const secret = computed(() => nestStore.getters.secret())
  const intent = computed<Intent>(() => intentStore.getters.intent())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const toggleDeleteDialog = () => {
    globalStore.mutations.toggleDeleteIntentDialog()
  }

  const resetIntent = async () => {
    const resetedIntent = {
      question: intent.value.question,
      answer: intent.value.answer,
    }

    intentStore.mutations.setResetedIntent(resetedIntent)
    await intentStore.actions.deleteIntent(orgId.value, nestId.value, intent.value.intentId)
    await intentStore.actions.fetchIntents(orgId.value, nestId.value, false)
    await chatStore.actions.askQuestion({
      question: resetedIntent.question,
      secretKey: secret.value,
      dialogId: trainingDialog.value.dialogId,
      training: true,
    })
    // globalStore.mutations.toggleResetIntentDialog()
  }
</script>

<style scoped lang="scss">
  .training-save {
    @apply w-full flex flex-col;

    &__header {
      @apply py-8 px-32;

      &-question {
        @apply truncate w-full mb-2;
      }
    }

    &__response {
      @apply bg-woot-50 dark:bg-woot-900 py-8 px-32;

      &-title {
        @apply text-woot-500 text-sm mb-2 break-words;
      }

      &-answer {
        @apply mb-2 break-words;
      }
    }
  }
</style>
