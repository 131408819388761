import { showErrors } from '@/utils/errors'
import userApi from '@/api/user'

export default (mutations: any) => {
  // export default (mutations: any) => {
  const fetchPermissions = async () => {
    try {
      // const { data } = await admin.fetchPermissions()
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const fetchUsers = async (page: number, email: string) => {
    try {
      const { data } = await userApi.getUsers(page, email)

      mutations.setUsers(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  return {
    fetchPermissions,
    fetchUsers,
  }
}
