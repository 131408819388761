<template>
  <div class="training-container">
    <EmptyState
      v-if="!intent && !resetedIntent && answers.length === 0"
      class="my-auto"
      img-name="NoMessages"
      :title="$t('training.content.start')"
    />
    <div v-else class="training-container__chat">
      <ChatAnswers v-if="answers.length > 0" @set-selected="setSelected" />
      <TrainingSave v-if="intent" />
    </div>
    <div class="training-container__actions">
      <AiTextarea
        v-if="answers.length === 0 && !intent"
        v-model="question"
        class="flex-1"
        :disabled="loading"
        :placeholder="$t('training.content.actions.type-away')"
        @keydown.enter.exact.prevent="askQuestion"
      >
        <template #appendIcon>
          <ChButton
            class="m-2"
            icon="send"
            rounded
            :size="ButtonSize.SM"
            :loading="loading"
            :disabled="!question || loading"
            @click="askQuestion"
          />
        </template>
      </AiTextarea>
      <button
        v-if="answers.length > 0 || intent"
        class="training-container__reset expand-btn bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700"
        @click="clear"
      >
        <ChIcon icon="undo" class="flex-shrink-0" />
        <span class="text-sm whitespace-nowrap">{{ $t('training.content.actions.reset') }}</span>
      </button>
      <ChButton
        v-if="answers.length > 0"
        :disabled="!isSelected"
        class="training-container__save"
        prepend-icon="checkmark-circle"
        @click="createIntent"
        >Save selected answer</ChButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import type { Answer } from '@/types/interfaces'
  import { useIntentStore } from '@/stores/intents'
  import { useRouteParams } from '@/composables/route-params'
  import { useChatStore } from '@/stores/chat'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import TrainingSave from '@/components/app/training/TrainingSave.vue'
  import AiTextarea from '@/components/ui/AiTextarea.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import ChatAnswers from '@/components/app/global/chat/ChatAnswers.vue'
  import { ButtonSize, ChButton, ChIcon } from '@chatlyncom/chatlyn-ui-components'

  const nestStore = useNestStore()
  const chatStore = useChatStore()
  const dialogStore = useDialogsStore()
  const intentStore = useIntentStore()

  const { orgId, nestId } = useRouteParams()

  const isSelected = ref(false)
  const loading = ref(false)
  const question = ref('')

  const selectedAnswer = ref<Answer | null>(null)

  const secret = computed(() => nestStore.getters.secret())
  const intent = computed(() => intentStore.getters.intent())
  const resetedIntent = computed(() => intentStore.getters.reseted())
  const answers = computed(() => chatStore.getters.answers())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const setSelected = (answer: Answer | null) => {
    isSelected.value = !!answer && answer.text.length > 0
    selectedAnswer.value = answer
  }

  const createIntent = async () => {
    if (selectedAnswer.value) {
      await intentStore.actions.createIntent(orgId.value, nestId.value, {
        question: question.value || resetedIntent.value.question,
        answer: selectedAnswer.value.text,
      })
      await intentStore.actions.fetchIntents(orgId.value, nestId.value, false)
    }
  }

  const askQuestion = async () => {
    toggleLoading()
    await chatStore.actions.askQuestion({
      question: resetedIntent.value ? resetedIntent.value.question : question.value,
      secretKey: secret.value,
      dialogId: trainingDialog.value.dialogId,
      training: true,
    })
    toggleLoading()
  }

  const toggleLoading = () => {
    loading.value = !loading.value
  }

  const clear = () => {
    question.value = ''
    selectedAnswer.value = null
    intentStore.mutations.setIntent(null)
    intentStore.mutations.setResetedIntent(null)
    chatStore.mutations.setAnswers([])
  }
</script>

<style scoped lang="scss">
  .training-container {
    @apply flex flex-col flex-1 bg-white dark:bg-gray-800 rounded-md shadow-xl border dark:border-gray-700 overflow-hidden;

    &__chat {
      @apply flex-1;
    }

    &__actions {
      @apply flex items-center justify-between p-5;
    }

    &__reset {
      @apply bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700 h-10 rounded-full flex flex-row flex-nowrap items-center gap-3 px-3 overflow-hidden max-w-10 hover:max-w-full transition-all duration-500 ease-in-out;
    }

    &__save {
      @apply ml-auto;
    }
  }
</style>
