import { toast } from 'vue3-toastify'
import resources from '@/api/nests/nests'

interface ResourcesParamsInterface {
  organizationId: string
  nestId: string
  page: any
  limit?: number
}

export default (mutations: any) => {
  const fetchResources = async ({ organizationId, nestId, page, limit = 50 }: ResourcesParamsInterface) => {
    try {
      mutations.clearResources()
      const { data } = await resources.fetchDataSources(organizationId, nestId, page || 1, limit)
      mutations.setResources(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchResourceUrl = async (organizationId: string, nestId: string, dataSourceId: string) => {
    try {
      const { data } = await resources.fetchFileUrl(organizationId, nestId, dataSourceId)
      window.open(data, '_blank')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchResourceText = async (organizationId: string, nestId: string, dataSourceId: string) => {
    try {
      const { data } = await resources.fetchText(organizationId, nestId, dataSourceId)
      mutations.setResource(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateResourceTitle = async (organizationId: string, nestId: string, dataSourceId: string, title: string) => {
    try {
      await resources.updateFileTitle(organizationId, nestId, dataSourceId, title)
      await toast.success('Successfully updated')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const uploadResource = async (organizationId: string, nestId: string, file: File) => {
    try {
      await resources.uploadFile(organizationId, nestId, file)
      await toast.success('Successfully uploaded')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const uploadResourceText = async (organizationId: string, nestId: string, data: any) => {
    try {
      await resources.uploadText(organizationId, nestId, data)
      await toast.success('Successfully created')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateResourceText = async (organizationId: string, nestId: string, dataSourceId: string, data: any) => {
    try {
      await resources.updateText(organizationId, nestId, dataSourceId, data)
      await toast.success('Successfully updated')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const deleteResource = async (organizationId: string, nestId: string, dataSourceId: string) => {
    try {
      await resources.deleteDataSource(organizationId, nestId, dataSourceId)
      await toast.success('Successfully deleted')
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchResources,
    fetchResourceUrl,
    fetchResourceText,
    updateResourceTitle,
    updateResourceText,
    uploadResource,
    uploadResourceText,
    deleteResource,
  }
}
