import user from '@/api/user'
import { showErrors } from '@/utils/errors'
import { toast } from 'vue3-toastify'

export default (mutations: any) => {
  const getMe = async () => {
    try {
      const { data } = await user.getMe()
      mutations.setUser(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const getUsers = async () => {
    try {
      const { data } = await user.getUsers(1, '')
      mutations.setUsers(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const getUser = async (id: string) => {
    try {
      const { data } = await user.getUser(id)
      mutations.setUser(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const updateUser = async (id: string, data: any) => {
    try {
      await user.updateUser(id, data)
      await toast.success('Successfully updated')
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const changeUserPassword = async (id: string, data: any) => {
    try {
      await user.changeUserPassword(id, data)
      await toast.success('Successfully changed')
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const suspendUser = async (id: string) => {
    try {
      await user.suspendUser(id)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  const createUser = async (data: any) => {
    try {
      await user.createUser(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  return {
    getMe,
    getUsers,
    getUser,
    updateUser,
    changeUserPassword,
    suspendUser,
    createUser,
  }
}
